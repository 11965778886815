import { Config } from './types';

const configs: Config[] = [
  {
    platform: 'tezos',
    network: 'mainnet',
    displayName: 'Tezos Mainnet',
    url: 'https://conseil-prod.cryptonomic-infra.tech:443',
    apiKey: 'galleon',
    entities: ['blocks', 'operations', 'accounts', 'bakers', 'governance'],
    nodeUrl: 'https://tezos-prod.cryptonomic-infra.tech:443',
    metadataOverrideUrl: 'https://raw.githubusercontent.com/Cryptonomic/Conseil/master/conseil-api/src/main/resources/metadata/tezos.mainnet.conf'
  },
  {
    platform: 'tezos',
    network: 'jakartanet',
    displayName: 'Tezos Jakartanet',
    url: 'https://conseil-jakarta.cryptonomic-infra.tech:443',
    apiKey: 'hooman',
    entities: ['blocks', 'operations', 'accounts', 'bakers', 'governance'],
    nodeUrl: 'https://tezos-jakarkta.cryptonomic-infra.tech:443'
  },

  {
    platform: 'tezos',
    network: 'kathmandunet',
    displayName: 'Tezos kathmandunet',
    url: 'https://conseil-kathmandu.cryptonomic-infra.tech:443',
    apiKey: 'hooman',
    entities: ['blocks', 'operations', 'accounts', 'bakers', 'governance'],
    nodeUrl: 'https://tezos-kathmandu.cryptonomic-infra.tech:443'
  },
  
  {
    platform: 'tezos',
    network: 'granadanet',
    displayName: 'Tezos Granadanet',
    url: 'https://conseil-granada.cryptonomic-infra.tech:443',
    apiKey: 'hooman',
    entities: ['blocks', 'operations', 'accounts', 'bakers', 'governance'],
    nodeUrl: 'https://tezos-granada.cryptonomic-infra.tech:443'
  }
]

export default configs;
