export const SET_ITEMS = 'SET_ITEMS';
export const SET_COLUMNS = 'SET_COLUMNS';
export const SET_FILTER = 'SET_FILTER';
export const SET_TAB = 'SET_TAB';
export const SET_LOADING = 'SET_LOADING';
export const SET_ATTRIBUTES = 'SET_ATTRIBUTES';
export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const REMOVE_ALL_FILTERS = 'REMOVE_ALL_FILTERS';
export const CHANGE_FILTER = 'CHANGE_FILTER';
export const REMOVE_VALUE = 'REMOVE_VALUE';
export const COMPLETE_FULL_LOAD = 'COMPLETE_FULL_LOAD';
export const SET_AVAILABLE_VALUES = 'SET_AVAILABLE_VALUES';
export const SET_SELECTED_VALUE = 'SET_SELECTED_VALUE';
export const SET_FILTER_COUNT = 'SET_FILTER_COUNT';
export const SET_SORT = 'SET_SORT';

export const SET_ENTITIES = 'SET_ENTITIES';
export const SET_ENTITY = 'SET_ENTITY';
export const SET_ENTITY_PROPERTIES = 'SET_ENTITY_PROPERTIES';

export const INIT_FILTER = 'INIT_FILTER';

export const SET_CONFIG = 'SET_CONFIG';
export const ADD_CONFIG = 'ADD_CONFIG';
export const REMOVE_CONFIG = 'REMOVE_CONFIG';
export const SET_AGGREGATIONS = 'SET_AGGREGATIONS';
export const SET_SUBMIT = 'SET_SUBMIT';

export const SET_QUERY_FILTERS = 'SET_QUERY_FILTERS';

//Home Page
export const SET_HOURLY_TRANSACTIONS_LOADING = 'SET_HOURLY_TRANSACTIONS_LOADING';
export const SET_HOURLY_TRANSACTIONS = 'SET_HOURLY_TRANSACTIONS';
export const SET_HOURLY_TRANSACTIONS_QUERY_URL= 'SET_HOURLY_TRANSACTIONS_QUERY_URL';
export const SET_TOP_ACCOUNTS = 'SET_TOP_ACCOUNTS';
export const SET_TOP_ACCOUNTS_LOADING = 'SET_TOP_ACCOUNTS_LOADING';
export const SET_TOP_BAKERS = 'SET_TOP_BAKERS';
export const SET_TOP_BAKERS_LOADING = 'SET_TOP_BAKERS_LOADING';
export const SET_TOP_ACCOUNTS_QUERY_URL = 'SET_TOP_ACCOUNTS_QUERY_URL';
export const SET_TOP_BAKERS_QUERY_URL = 'SET_TOP_BAKERS_QUERY_URL';
